<template>
<div id="app">

    <v-row>

        <v-col cols="5" lg="2">
            <v-select :items="list_type_recherche" v-model="selecttype_recherche" dense outlined label="Type de recherche">

            </v-select>
        </v-col>
        <v-col lg="2" cols="5">
            <v-text-field v-model="libelle_recherche" label="Recherche..." dense outlined></v-text-field>

        </v-col>
        <v-col>
            <v-btn color="primary" dark class="mb-2" @click="readAll">
                Recherche
            </v-btn>

        </v-col>

        <v-col v-if="null">
            <v-btn color="green" dark class="mb-2" @click="Actulisation()">
                Actualiser
            </v-btn>

        </v-col>

    </v-row>

    <v-data-table :headers="headers" :items="list" sort-by="code" class="elevation-1">

        <template v-slot:top>
            <v-toolbar flat>
                <v-toolbar-title>Liste des monnaires</v-toolbar-title>
                <v-divider class="mx-4" inset vertical></v-divider>
                <v-spacer></v-spacer>

                <template>
                    <v-btn color="primary" dark class="mb-2" @click="nouveau()">
                        <v-icon dark>mdi-plus</v-icon> Nouveau
                    </v-btn>

                </template>

                <v-dialog v-model="dialog" max-width="800px">
                    <v-card>
                        <v-toolbar flat color="blue">
                            <v-toolbar-title v-if="!etat">Nouvelle monnaies</v-toolbar-title>
                            <v-toolbar-title v-if="etat">Modification monnaies</v-toolbar-title>
                            <v-spacer></v-spacer>
                            <v-btn icon dark @click="dialog = false">
                                <v-icon>mdi-close</v-icon>
                            </v-btn>
                        </v-toolbar>
                        <v-card-text>
                            <v-row>
                                <v-col cols="12" sm="6" md="4">
                                    <v-text-field v-model="detail_Formulaire.libelle_monnaie" label="Monnaies" outlined></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="6" md="4">
                                    <v-select :items="list_type_monnaie" v-model="detail_Formulaire.type_monnaie" dense outlined label="Type de Monnaies" />
                                </v-col>

                                <v-col cols="12" sm="6" md="4">
                                    <v-text-field v-model="detail_Formulaire.adresse" label="Adresse ou numero du sim" outlined></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="6" md="4">
                                    <v-text-field v-model="detail_Formulaire.reserve" label="Reserve" outlined></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="6" md="4">
                                    <v-text-field v-model="detail_Formulaire.frais_depot" label="Frais de dépot" outlined></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="6" md="4">
                                    <v-text-field v-model="detail_Formulaire.frais_retrait" label="Frais de retrait" outlined></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="6" md="4">
                                    <v-text-field v-model="detail_Formulaire.min_depot" label="Minimun de dépot" outlined></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="6" md="4">
                                    <v-text-field v-model="detail_Formulaire.min_retrait" label="Minimun de retrait" outlined></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="6" md="4">
                                    <v-text-field v-model="detail_Formulaire.max_depot" label="Maximum de dépot" outlined></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="6" md="4">
                                    <v-text-field v-model="detail_Formulaire.max_retrait" label="Maximum de retrait" outlined></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="6" md="4">
                                    <v-text-field v-model="detail_Formulaire.code_local" label="Code locale" outlined></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="6" md="4">
                                    <v-text-field v-model="detail_Formulaire.symbol" label="Symbole" outlined></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="6" md="4">
                                    <v-select :items="list_pays" v-model="detail_Formulaire.pays" dense outlined label="Pays" />
                                </v-col>
                                <v-col cols="12" sm="6" md="4">
                                    <v-text-field v-model="detail_Formulaire.logo" label="le lien du logo" outlined></v-text-field>

                                </v-col>

                            </v-row>

                        </v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="green" dark @click="save()">Enregistrer</v-btn>
                            <v-spacer></v-spacer>
                        </v-card-actions>
                    </v-card>
                </v-dialog>

                <v-dialog v-model="dialog2" max-width="400">

                    <v-card>
                        <v-toolbar flat color="blue">
                            <v-toolbar-title v-if="!editdirection">Nouvelle Direction</v-toolbar-title>
                            <v-toolbar-title v-if="editdirection">Modification Direction</v-toolbar-title>
                            <v-spacer></v-spacer>
                            <v-btn icon dark @click="dialog2 = false">
                                <v-icon>mdi-close</v-icon>
                            </v-btn>
                        </v-toolbar>
                        <v-card-text>
                            <v-col cols="12">
                                <v-select :items="list_direction" v-model="selectdirection" dense outlined label="Direction de transfert" />
                            </v-col>

                        </v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="green" dark @click="updateItemDirection()">Enregistrer</v-btn>
                            <v-spacer></v-spacer>
                        </v-card-actions>
                    </v-card>
                </v-dialog>

                <v-dialog v-model="dialog_reserve" max-width="400">
                  <v-card>
                      <v-toolbar flat color="blue">
                          <v-toolbar-title >Mise à jour reserve</v-toolbar-title>
                          <v-spacer></v-spacer>
                          <v-btn icon dark @click="dialog_reserve = false">
                              <v-icon>mdi-close</v-icon>
                          </v-btn>
                      </v-toolbar>
                      <v-card-text>
                          <v-col cols="12">
                              <v-text-field v-model="maj_reserve" label="Reserve" outlined>

                              </v-text-field>
                          </v-col>

                      </v-card-text>
                      <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn color="green" dark @click="updateReserve()">Enregistrer</v-btn>
                          <v-spacer></v-spacer>
                      </v-card-actions>
                  </v-card>
                </v-dialog>

                <v-dialog v-model="dialog3" max-width="400px">

                    <v-card>
                        <v-toolbar flat color="blue">
                            <v-toolbar-title v-if="!edit">Nouveau mode de transfert</v-toolbar-title>
                            <v-toolbar-title v-if="edit">Modification mode de transfert</v-toolbar-title>
                            <v-spacer></v-spacer>
                            <v-btn icon dark @click="dialog3 = false">
                                <v-icon>mdi-close</v-icon>
                            </v-btn>
                        </v-toolbar>
                        <v-card-text>
                            <v-col cols="12">
                                <v-select :items="list_mode" v-model="selectmode" dense outlined label="Mode de transfert" />
                            </v-col>

                        </v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="green" dark @click="updateItemMode()">Enregistrer</v-btn>
                            <v-spacer></v-spacer>
                        </v-card-actions>
                    </v-card>
                </v-dialog>

            </v-toolbar>
        </template>

        <template v-slot:item.reserve="{ item }">
            <v-chip :color="item.reserve<1000?'primary':item.direction==1?'orange':'green'" text-color="white">
                {{item.reserve}}
            </v-chip>
            <v-btn icon @click="dialogReserve(item)">
                <v-icon color="blue" large class="mr-2">
                    mdi-pencil
                </v-icon>
            </v-btn>
        </template>

        <template v-slot:item.status="{ item }">
            <v-switch v-model="item.status" :color="item.status==1?'green':'red'" :label="item.status==1?'Activer':'Desactiver'" inset @change="Action_status(item)">

            </v-switch>

        </template>

        <template v-slot:item.direction="{ item }">
            <v-chip :color="item.direction==0?'primary':item.direction==1?'orange':'green'" text-color="white">
                {{item.direction==0?'Entrant':item.direction==1?'Sortant':'Entrant et Sortant'}}
            </v-chip>
            <v-btn icon @click="dialogdirectionitem(item)">
                <v-icon color="blue" large class="mr-2">
                    mdi-pencil
                </v-icon>
            </v-btn>
        </template>


        <template v-slot:item.mode="{ item }">

            <v-chip :color="item.mode==0?'primary':item.mode==1?'orange':'green'" text-color="white">
                {{item.mode==1?'AUTOMATIQUE':'MANUELLE'}}
            </v-chip>
            <v-btn icon @click="dialogmodeitem(item)">
                <v-icon color="blue" large class="mr-2">
                    mdi-pencil
                </v-icon>
            </v-btn>
        </template>

        <template v-slot:[`item.actions`]="{ item }">
            <v-btn icon @click="detail(item)">
                <v-icon color="red" large class="mr-2">
                    mdi-delete
                </v-icon>
            </v-btn>
            <v-btn icon @click="getItm(item)">
                <v-icon color="blue" large class="mr-2">
                    mdi-pencil
                </v-icon>
            </v-btn>

        </template>


        <template v-slot:no-data>
            <v-btn color="primary" @click="Actulisation">
                Actualiser la liste
            </v-btn>
        </template>
    </v-data-table>

</div>
</template>

<script>
//import api from '../../../serviceApi/apiService'
import api from '@/serviceApi/apiService'

export default {
    name: "Monnaie",

    metaInfo: {
        // title will be injected into parent titleTemplate
        title: 'Monnaie'
    },

    data: () => ({
        dialog: false,
        dialog2: false,
        dialog3: false,
        dialog_reserve: false,
        search: "",
        detail_Formulaire: "",
        selectdirection: "",
        maj_reserve: "",
        selectmode: "",
        headers: [{
                text: "Actions",
                align: "start",
                sortable: false,
                value: "actions",
                divider: true,
                width: '150px'

            },
            {
                text: "Status",
                value: "status",
                divider: true,
                width: '50px'
            },

            {
                text: "Libelle monnaie",
                align: "start",
                sortable: false,
                value: "libelle_monnaie",
                divider: true,
                width: '150px'
            },

            {
                text: "Type monnaie",
                align: "start",
                sortable: false,
                value: "type_monnaie",
                divider: true,
                width: '80px'
            },
            {
                text: "Adresse monnaie",
                align: "start",
                sortable: false,
                value: "adresse",
                divider: true,
                width: '150px'
            },
            {
                text: "Reserve",
                align: "start",
                sortable: false,
                value: "reserve",
                divider: true,
                width: '150px'
            },
            {
                text: "Frais de depot",
                align: "start",
                sortable: false,
                value: "frais_depot",
                divider: true,
                width: '80px'
            },
            {
                text: "Frais de retrait",
                align: "start",
                sortable: false,
                value: "frais_retrait",
                divider: true,
                width: '80px'
            },

            {
                text: "Minimum depot",
                align: "start",
                sortable: false,
                value: "min_depot",
                divider: true,
                width: '100px'
            },
            {
                text: "Minimum retrait",
                align: "start",
                sortable: false,
                value: "min_retrait",
                divider: true,
                width: '100px'
            },
            {
                text: "Maximum depot",
                align: "start",
                sortable: false,
                value: "max_depot",
                divider: true,
                width: '100px'
            },
            {
                text: "Maximum retrait",
                align: "start",
                sortable: false,
                value: "max_retrait",
                divider: true,
                width: '100px'
            },
            {
                text: "Mode de transfert",
                align: "start",
                sortable: false,
                value: "mode",
                divider: true,
                width: '100px'
            },
            {
                text: "Direction",
                align: "start",
                sortable: false,
                value: "direction",
                divider: true,
                width: '100px'
            },
            {
                text: "Code monnaie locale",
                align: "start",
                sortable: false,
                value: "code_local",
                divider: true,
                width: '100px'
            },
            {
                text: "Symbole de monnaie",
                align: "start",
                sortable: false,
                value: "symbol",
                divider: true,
                width: '100px'
            },
            {
                text: "Logo",
                align: "start",
                sortable: false,
                value: "logo",
                divider: true,
                width: '100px'
            },
            {
                text: "Pays",
                align: "start",
                sortable: false,
                value: "pays",
                divider: true,
                width: '100px'
            },

        ],

        commentaire: '',
        selectstatus: '',
        detail_Formulaire: {
            libelle_monnaie: '',
            type_monnaie: '',
            adresse: '',
            reserve: '',
            frais_depot: 0,
            frais_retrait: 0,
            min_depot: 0,
            min_retrait: 0,
            max_depot: 0,
            max_retrait: 0,
            mode: 0,
            direction: 0,
            status: 0,
            code_local: 0,
            network: null,
            symbol: null,
            prefix: null,
            pays: null,
            logo: null,
            id: 0,

        },

        list: [],
        list_profils: [],
        selectprofil: 0,
        list_type_recherche: ['CODE CLIENT', 'E-MAIL'],
        valeur: '',
        etat: false,
        selecttype_recherche: '',
        libelle_recherche: '',
        list_status: ['Valider', 'Bloquer', 'En attente'],
        list_direction: ['ENTREE', 'SORTIE', 'ENTREE ET SORTIE'],
        list_mode: ['MANUELLE', 'AUTOMATIQUE'],
        selectdirection: '',
        selectmode: '',

    }),

    created() {
        this.readAll_profils();
        this.readAll();

    },
    methods: {

        readAll: async function () {
            let fd = new FormData();
            const data = await api.createUpdatedata('backoffice/all-monnaie', fd);
            this.list = data;

        },
        readAll_profils: async function () {

            const data = await api.readAll('backoffice/all-profil');
            this.list_profils = data;

        },

        detail: async function (item) {
            this.detail_Formulaire.nom_prenom = item.nom_prenom;
            this.detail_Formulaire.email = item.email;
            this.detail_Formulaire.id = item.id;
            this.detail_Formulaire.profil = item.profils.id;
            this.etat = true;
            this.dialog = true;
        },

        save: async function () {
            if (this.detail_Formulaire.cpassword != this.detail_Formulaire.password) {
                this.showAlert('Les mots de passe ne sont pas identiques', 'error');
                return;
            }
            let fd = new FormData();
            fd.append('nom_prenom', this.detail_Formulaire.nom_prenom);
            fd.append('email', this.detail_Formulaire.email);
            fd.append('password', this.detail_Formulaire.password);
            fd.append('profil', this.detail_Formulaire.profil);
            if (this.etat == false) {
                const data = await api.createUpdatedata('backoffice/compte-admins-register', fd);

                if (data.status == true) {
                    this.showAlert(data.message, 'success');
                    this.dialog = false;
                    this.readAll();
                    this.clearItem();
                } else {
                    this.clearItem();
                    this.showAlert(data.message, 'error');
                }
            } else {
                const data = await api.createUpdatedata('backoffice/compte-admins-update/' + this.detail_Formulaire.id, fd);

                if (data.status == true) {
                    this.showAlert(data.message, 'success');
                    this.dialog = false;
                    this.readAll();
                    this.clearItem();
                } else {
                    this.showAlert(data.message, 'error');
                }
            }

        },

        nouveau: async function () {
            this.clearItem();
            this.dialog = true;
            this.etat = false;
        },

        getItm(item) {
            this.detail_Formulaire = item;
            this.dialog = true;
            this.etat = true;
        },

        async updateItemMode() {
            let fd = new FormData();
            fd.append('id', this.detail_Formulaire.id);
            fd.append('mode', this.selectmode=='MANUELLE'?2:1);
            const data=await api.createUpdatedata('backoffice/update-monnaie-mode',fd);
            if (data.status == true) {
                this.showAlert(data.message, 'success');
                this.dialog3 = false;
                this.readAll();
                api.sendTelegram("Modification du mode de "+this.detail_Formulaire.libelle_monnaie);
                this.clearItem();

            } else {
                this.showAlert(data.message, 'error');
            }
        },

        async updateItemDirection() {
            let fd = new FormData();
            fd.append('id', this.detail_Formulaire.id);
            fd.append('direction', this.selectdirection=='ENTREE'?0:this.selectdirection=='SORTIE'?1:2);
            const data=await api.createUpdatedata('backoffice/update-monnaie-direction',fd);
            if (data.status == true) {
                this.showAlert(data.message, 'success');
                this.dialog2 = false;
                this.readAll();
                api.sendTelegram("Modification de la direction de  "+this.detail_Formulaire.libelle_monnaie);
                this.clearItem();

            } else {
                this.showAlert(data.message, 'error');
            }
        },

        async updateReserve() {
            let fd = new FormData();
            fd.append('id', this.detail_Formulaire.id);
            fd.append('reserve', this.maj_reserve);
            //console.log('id '+this.detail_Formulaire.id+' reserve '+this.maj_reserve);
             const data=await api.createUpdatedata('backoffice/update-monnaie-reserve',fd);
             this.dialog_reserve = false;
            if (data.status == true) {
                this.showAlert(data.message, 'success');
                this.readAll();
                api.sendTelegram("Modification de la reserve de  "+this.detail_Formulaire.libelle_monnaie);
                this.clearItem();

            } else {
                this.showAlert(data.message, 'error');
            }
        },

        dialogmodeitem(item) {
            this.detail_Formulaire = item;
            this.dialog3 = true;
        },
        dialogdirectionitem(item) {
            this.detail_Formulaire = item;
            this.dialog2 = true;
        },

        dialogReserve(item) {
            this.detail_Formulaire = item;
            this.maj_reserve = item.reserve;
            this.dialog_reserve = true;
        },

        clearItem() {
            this.detail_Formulaire = {
                id: '',
                nom_prenom: '',
                email: '',
                password: '',
                cpassword: '',
            }

        },

        async Action_status(item) {
            const data = await api.readAll('backoffice/etat-monnaie/' + item.id);
            if (data.status == true) {
                this.showAlert(data.message, 'success');
            } else {
                this.showAlert(data.message, 'error');
            }
        },

        getmode(item) {
            if (item == 2) {
                return 'MANUELLE';
            } else {
                return 'AUTOMATIQUE';
            }
        },

        showAlert(textmessage, txticone) {
            this.$swal.fire({
                position: 'top-end',
                icon: txticone,
                title: textmessage,
                showConfirmButton: false,
                timer: 1500
            })
        },

        fetchData() {
            this.readAll();
        },

    },
};
</script>
